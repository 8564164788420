<template>
  <transition name="fade-up">
    <div v-show="visible">
      <div class="v-model" @click="modelClick">
      </div>
      <div class="rule-modal">
      <div class="rule-header">
        <div class="title">活动规则</div>
        <div class="close-btn" @click="handleClose">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUg
            AAADAAAAAwBAMAAAClLOS0AAAAKlBMVEUAA
            AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
            AAAAKE86IAAAADXRSTlMAgKq
            flKSZiIWPjOW/w/SrwQAAAMZJREFUOMvl0MsJwkAAhOFZH4iHQBp
            ICYINCGlASANCGhAsIQ0IlmADgl14NRqfML1IXMlsdnPxnP
            +0S/YjMOhF5uTekrg5RsycVzw25xFvDuBcl5Uuxn2EMa8CGZwWzH
            /g+0QNWekPrdZMLbij3YgvC1J4LbmvQQW/KR812CHowMLwjb
            AJy4QbdLQln90bk/F/HxKy7AaXWZto1oFHNKtHNKtPNKuIQA
            4ERLMGxM4aEs3qETtrSIydNSQRC0DkLFFrkRx96ANtrVRxjAK
            rugAAAABJRU5ErkJggg==" alt="">
        </div>
      </div>
      <div class="rule-content">
        <div class="module">
          <p>挑战一、注册有鱼记账后，3天内完成首次记账，即可获得1天会员（每位用户仅限参与1次）</p>
          <p>挑战二、注册有鱼记账后，7天内累计完成3天记账，即可获得3天会员（每位用户仅限参与1次）</p>
          <p>挑战三、有鱼记账用户可随时报名参加记账挑战，自报名之日起，14天内累计完成8天记账，即可获得5天会员（每位用户仅限参与1次）</p>
        </div>
        <div class="module">
          <p>关于活动中提及的【记账】的定义与解释：</p>
          <p>①参与活动期内，仅限每日点击首页【记一笔】，手动记录支出/收入/转账，方可视为一次记账成功</p>
          <p>②活动期内如每日多次记账，仅视为成功记账1天</p>
          <p>③同一天补记多天账单，仅以记账当天实际时间为准，被补记的账单不计入任务进度</p>
          <p>④奖品自任务完成后即时发放并生效，任务奖励不可折现，转赠</p>
          <p>⑤如有作弊行为，一经发现，取消领奖资格并追回已发放奖励</p>
          <p>本次活动最终解释权归活动方所有</p>
          <p>如有其他疑问，可扫码联系有鱼记账官方客服微信：</p>
          <p>
            <img src="https://download-cos.yofish.com/youyu-gongjushiyebu/20220129104114654-79.png" alt="">
          </p>
        </div>
      </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'RuleModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    modelClick() {
      this.handleClose();
    },
  },
};
</script>
<style lang="scss" scoped>
  .v-model {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .rule-modal {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 750px;
    background-color: #fff;
    border-radius: 22px 22px 0px 0px;
  }
  .rule-header {
    text-align: center;
    font-size: 34px;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    margin: 37px 0 36px 0;
    position: relative;
  }
  .rule-content {
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    max-height: 780px;
    padding: 0 26px;
    overflow: auto;
    .module {
      margin-bottom: 20px;
      img {
        width: 200px;
      }
    }
  }
  .close-btn {
    width: 51px;
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .fade-up-enter,.fade-up-enter-active{
    -webkit-transform: translate(0,-100%);
    transform: translateY(-100%)
}
</style>
